body {
  background-color: rgb(37, 36, 36);
}

a {
  text-decoration: none;
}

.container {
  max-width: 800px; /* or any max-width you prefer */
  margin: 0 auto; /* this centers the container */
  padding: 20px 20px; /* this adds spacing on the sides */
  background-color: #e9f2e97c;
  position: relative;
  min-height: 300px;
}

.upload-btn {
}

.download-btn {
  margin-left: 10px;
}

.log-in-btn {
  margin-left: 10px;
}

.log-in-email-input {
  width: 140px;
}

.footer {
  position: absolute;
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.footer-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* top right of page */
.workspaces {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.workspace-selected {
  background-color: #e9f2e97c;
}

.login-notification {
  color: wheat;
  margin-right: 10px;
}
