.bookmark-li {
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  border: 1px solid #1a1a1a;
  background-color: #f2f2f2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.bookmark-cursor {
  margin-right: 10px;
}

.expanded-bookmark-li {
  border-bottom-right-radius: 0px;
  background-color: #cfc7c7;
}

.bookmark-li a {
  margin-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.bookmark-li img {
  width: 24px;
  height: 24px;
}

.corkscrew-img {
  margin-left: auto;
  margin-right: 5px;
}

.edit-bookmark-div {
  margin-left: auto;
  display: none;
  padding-left: 5px;
  padding-right: 5px;
  color: #0000004d;
  cursor: pointer;
}

.bookmark-li:hover .edit-bookmark-div {
  display: flex;
  align-items: center;
}

/* change color */
.bookmark-li:hover .edit-bookmark-div:hover {
  background-color: #cfc7c7;
  border-radius: 5px;
  color: #000000d3;
}

.save-bookmark-button {
  margin-left: auto;
}

.expanded-li {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 100%;
  left: 40px;
  background: #cfc7c7;
  border-left: 1px solid #1a1a1a;
  border-right: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
  height: auto;
  margin-right: -0.6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.bookmark-name-input {
  width: 100%;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  font-size: 15px;
  background-color: #ffffffa8;
  border: 1px solid #1a1a1a;
  padding: 5px;
}

a[contenteditable="true"] {
  width: 100%;
  margin-right: 10px;
}

/* align to left of save button */
.delete-bookmark-btn {
  margin-right: 10px;
}

input {
  border: none;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  background-color: #ffffffa8;
  border: 1px solid #1a1a1a;
}

.bookmark-url-input {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tags-input-container {
  display: flex;
  flex-wrap: wrap;
}

/* width spans as much as possible */
.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tags-input {
  width: 140px;
  order: -1;
}

.tag {
  margin-left: 10px;
}

/* circle with x centere */
.tag button {
  margin-left: 5px;
  border-radius: 50%;
  border: none;
  padding: 0px;
  width: 20px;
  height: 20px;
}

.tag-header-div {
  display: flex;
  align-items: center;
}

.tag-name-header {
  margin-right: 10px;
}

/* focus on tag header with color */
.tag-name-header[contenteditable="true"] {
  background-color: #f3e8aa;
  border-radius: 5px;
  padding-right: 10px;
}

.tag-header-edit-btn {
  display: none;
}

.tag-header-div:hover .tag-header-edit-btn {
  display: inline-block;
  margin-left: auto;
  margin-right: 20px;
}

.tag-header-save-btn {
  margin-left: auto;
  margin-right: 20px;
}
