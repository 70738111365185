/* green rounded button */
.omnibox-add-btn {
  padding: 4px 16px;
  text-align: center;
  font-size: 14px;
  margin: 4px 2px;
}

.omnibox-input {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
}
